import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import axios from 'axios';
import { motion } from 'framer-motion';
import { FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const UpgradePlan = () => {
  const [clientSecret, setClientSecret] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userPlan, setUserPlan] = useState(null);
  const [books, setBooks] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userCount, setUserCount] = useState(353);

  const plans = [
    { id: 'basic', name: 'Basic', price: 6, books: 5, support: 'Email support', type: 'one-time' },
    { id: 'subscribe', name: 'Subscribe', price: 9, books: 10, support: 'Chat support', type: 'monthly' },
    { id: 'pro', name: 'Pro', price: 15, books: 'Unlimited', support: 'Chat support', type: 'monthly' },
  ];

  const commonFeatures = [
    '10 Genres of books to choose from',
    '10 Pages per book',
    'Generate books from photos',
    'Educational, story, or rhyming options',
    'Print option (coming soon)',
    'Credits never expire',
    '7-day money-back guarantee'
  ];

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
    if (token) {
      fetchUserData();
    }
  }, []);

  useEffect(() => {
    if (selectedPlan) {
      fetchClientSecret(selectedPlan);
    }
  }, [selectedPlan]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (Math.random() < 0.3) { // 30% chance to increase every 3 seconds
        setUserCount(prevCount => prevCount + 1);
      }
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('token');
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3004';
      
      const userResponse = await axios.get(`${apiBaseUrl}/api/user`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setUserPlan(userResponse.data.plan);

      const booksResponse = await axios.get(`${apiBaseUrl}/api/books`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setBooks(booksResponse.data);
    } catch (err) {
      console.error('Error fetching user data:', err);
    }
  };

  const fetchClientSecret = async (plan) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3004';

      const response = await axios.post(
        `${apiBaseUrl}/api/create-payment-intent`,
        { planId: plan.id, amount: plan.price * 100 },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setClientSecret(response.data.clientSecret);
    } catch (err) {
      console.error('Failed to create payment intent:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setClientSecret('');
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <motion.div 
      className="min-h-screen bg-gradient-to-br from-purple-300 to-pink-200 py-12 px-4 sm:px-6 lg:px-8"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <div className="max-w-6xl mx-auto">
        {!isLoggedIn && (
          <motion.div 
            className="bg-gradient-to-r from-purple-600 to-pink-500 rounded-xl shadow-lg p-8 mb-12 text-center"
            variants={itemVariants}
          >
            <h1 className="text-4xl font-bold text-white mb-4">Get 5 Books for FREE Today!</h1>
            <p className="text-xl text-white mb-6">Create magical stories for your kids in minutes. No credit card required.</p>
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Link 
                to="/register" 
                className="bg-white text-purple-600 px-8 py-3 rounded-full font-semibold text-lg hover:bg-purple-100 transition duration-300 inline-block"
              >
                Start Creating for Free
              </Link>
            </motion.div>
          </motion.div>
        )}

        {isLoggedIn && (
          <motion.div 
            className="bg-white rounded-xl shadow-md p-6 mb-8"
            variants={itemVariants}
          >
            <h2 className="text-2xl font-semibold mb-4 text-purple-700">Your Current Plan</h2>
            {isLoggedIn ? (
              userPlan ? (
                <>
                  <p className="text-lg text-gray-700 mb-2">Plan: <span className="font-semibold">{userPlan.name}</span></p>
                  <p className="text-lg text-gray-700 mb-4">Books Created: <span className="font-semibold">{books.length} / {userPlan.books === 'Unlimited' ? '∞' : userPlan.books}</span></p>
                  <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                    <div 
                      className="bg-purple-600 h-2.5 rounded-full" 
                      style={{ width: `${(books.length / (userPlan.books === 'Unlimited' ? books.length : userPlan.books)) * 100}%` }}
                    ></div>
                  </div>
                  <p className="text-purple-700">
                    {userPlan.books === 'Unlimited' 
                      ? 'You have unlimited book creation!' 
                      : books.length >= userPlan.books
                        ? 'You have reached your book limit. Choose a plan below to create more books.'
                        : `You have ${Math.max(0, userPlan.books - books.length)} book credits remaining.`
                    }
                  </p>
                </>
              ) : (
                <p className="text-red-500">Unable to retrieve plan information. Please contact support.</p>
              )
            ) : (
              <p className="text-purple-700">
                You're not logged in. <a href="/login" className="text-purple-500 hover:underline">Log in</a> or <a href="/register" className="text-purple-500 hover:underline">register</a> to receive your 5 free books!
              </p>
            )}
          </motion.div>
        )}

        <motion.div 
          className="flex flex-col items-center justify-center mb-12 space-y-6"
          variants={itemVariants}
        >
          <motion.h1 
            className="text-4xl font-bold text-center text-purple-800 font-display"
            variants={itemVariants}
          >
            Our Pricing Plans
          </motion.h1>
          
          <motion.div 
            className="flex items-center bg-white rounded-full shadow-md p-2 px-6"
            variants={itemVariants}
          >
            <div className="flex -space-x-2 mr-4">
              {[1, 2, 3, 4, 5, 6].map((i) => (
                <img
                  key={i}
                  className="inline-block h-10 w-10 rounded-full ring-2 ring-white"
                  src={`https://randomuser.me/api/portraits/men/${i}.jpg`}
                  alt={`User avatar ${i}`}
                />
              ))}
            </div>
            <span className="text-purple-700 font-semibold text-lg">
              {userCount} happy users this month
            </span>
          </motion.div>
        </motion.div>

        <motion.div 
          className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8 relative"
          variants={itemVariants}
        >
          {plans.map((plan, index) => (
            <motion.div
              key={plan.id}
              className={`bg-white rounded-xl shadow-md p-6 cursor-pointer transition-all duration-300 transform hover:scale-105 hover:shadow-lg ${
                selectedPlan?.id === plan.id ? 'ring-2 ring-purple-500' : ''
              } ${
                plan.id === 'subscribe' ? 'md:col-start-2 md:row-start-1 md:transform md:scale-105 md:z-10 md:shadow-lg relative overflow-visible pt-8 hover:scale-110' : ''
              }`}
              onClick={() => handlePlanSelect(plan)}
              variants={itemVariants}
              whileHover={{
                scale: plan.id === 'subscribe' ? 1.10 : 1.05,
                transition: { duration: 0.2 }
              }}
            >
              {plan.id === 'subscribe' && (
                <>
                  <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-yellow-400 text-xs font-bold px-3 py-1 rounded-full text-purple-800 z-20">
                    MOST POPULAR
                  </div>
                  <div className="absolute inset-0 bg-gradient-to-br from-purple-400 to-pink-300 opacity-20 rounded-xl"></div>
                  <div className="absolute -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 rounded-xl blur opacity-25 group-hover:opacity-40 transition duration-1000 group-hover:duration-200"></div>
                </>
              )}
              <div className="relative">
                <h2 className={`text-2xl font-semibold mb-4 ${plan.id === 'subscribe' ? 'text-purple-600' : 'text-purple-700'}`}>{plan.name}</h2>
                <p className={`text-3xl font-bold mb-4 ${plan.id === 'subscribe' ? 'text-purple-600' : ''}`}>
                  ${plan.price}<span className="text-sm font-normal">/{plan.type === 'monthly' ? 'month' : 'one-time'}</span>
                </p>
                <ul className="text-gray-600 mb-4">
                  <li className="flex items-center mb-2">
                    <FaCheck className="text-green-500 mr-2 flex-shrink-0" />
                    <span>{typeof plan.books === 'number' ? `${plan.books} books` : plan.books}</span>
                  </li>
                  <li className="flex items-center mb-2">
                    <FaCheck className="text-green-500 mr-2 flex-shrink-0" />
                    <span>{plan.support}</span>
                  </li>
                  {commonFeatures.map((feature, index) => (
                    <li key={index} className="flex items-center mb-2">
                      <FaCheck className="text-green-500 mr-2 flex-shrink-0" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
                <button
                  className={`w-full py-2 rounded-full ${
                    selectedPlan?.id === plan.id
                      ? 'bg-purple-600 text-white'
                      : plan.id === 'subscribe'
                      ? 'bg-purple-600 text-white hover:bg-purple-700'
                      : 'bg-purple-100 text-purple-600 hover:bg-purple-200'
                  } transition-colors duration-300`}
                >
                  {selectedPlan?.id === plan.id ? (isLoading ? 'Loading...' : 'Selected') : 'Select'}
                </button>
              </div>
            </motion.div>
          ))}
        </motion.div>
        {selectedPlan && (isLoading ? (
          <motion.div 
            className="bg-white rounded-xl shadow-md p-6 text-center"
            variants={itemVariants}
          >
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-500 mx-auto"></div>
            <p className="mt-4 text-purple-700">Preparing payment form...</p>
          </motion.div>
        ) : clientSecret ? (
          <motion.div 
            className="bg-white rounded-xl shadow-md p-6"
            variants={itemVariants}
          >
            <h2 className="text-2xl font-semibold mb-4 text-purple-700">Complete Your Purchase</h2>
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <CheckoutForm planDetails={selectedPlan} clientSecret={clientSecret} />
            </Elements>
          </motion.div>
        ) : null)}

        {/* Photo section without any framing or shadowing */}
        <motion.div
          className="mt-16"
          variants={itemVariants}
        >
          <h2 className="text-4xl font-bold text-purple-800 mb-8 text-center">
            Create fun and educational stories with your child today!
          </h2>
          <img
            src="./images/Alex-Sample-Photo.png"
            alt="Child reading a personalized book"
            className="w-full h-auto object-cover rounded-xl"
          />
          <p className="mt-6 text-xl text-purple-700 text-center">
            Spark your child's imagination and foster a love for reading with our personalized storybooks.
          </p>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default UpgradePlan;
