import React, { useState, useEffect } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';

const CheckoutForm = ({ planDetails, clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      setMessage("Unable to initialize payment. Please try again.");
      return;
    }

    // Remove the immediate call to retrievePaymentIntent
    // We'll only show messages for actual payment attempts
  }, [stripe, clientSecret]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    setMessage(null); // Clear any previous messages

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment-complete?planId=${planDetails.id}`,
      },
    });

    if (result.error) {
      setMessage(result.error.message);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        navigate(`/payment-complete?payment_intent_client_secret=${clientSecret}&planId=${planDetails.id}`);
      }
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  };

  return (
    <>
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Order Summary</h3>
        <p><strong>Plan:</strong> {planDetails.name}</p>
        <p><strong>Price:</strong> ${planDetails.price}/{planDetails.type === 'monthly' ? 'month' : 'one-time'}</p>
        <p><strong>Books:</strong> {planDetails.books}</p>
        <p><strong>Support:</strong> {planDetails.support}</p>
      </div>
      <form onSubmit={handleSubmit} className="space-y-4">
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button
          disabled={isLoading || !stripe || !elements}
          className="w-full bg-purple-700 text-white py-3 rounded-full hover:bg-purple-800 transition duration-300 disabled:opacity-50"
        >
          <span className="inline-block">
            {isLoading ? (
              <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            ) : (
              `Pay $${planDetails.price}`
            )}
          </span>
        </button>
        {message && <div className="text-red-500 mt-2">{message}</div>}
      </form>
    </>
  );
};

export default CheckoutForm;