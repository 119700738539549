import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useAuth } from '../AuthContext';
import axios from 'axios';

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isLoggedIn, logout } = useAuth();
  const navigate = useNavigate();
  const [userPlan, setUserPlan] = useState(null);
  const [books, setBooks] = useState([]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchUserData();
    }
  }, [isLoggedIn]);

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('token');
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3004';
      
      const userResponse = await axios.get(`${apiBaseUrl}/api/user`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setUserPlan(userResponse.data.plan);

      const booksResponse = await axios.get(`${apiBaseUrl}/api/books`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setBooks(booksResponse.data);
    } catch (err) {
      console.error('Error fetching user data:', err);
    }
  };

  const handleCreateNow = () => {
    if (isLoggedIn && userPlan) {
      const bookLimit = userPlan.books === 'Unlimited' ? Infinity : userPlan.books;
      if (books.length < bookLimit) {
        navigate('/child-theme-book');
      } else {
        navigate('/upgrade-plan');
      }
    } else {
      navigate('/child-theme-book');
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const menuItems = [
    { to: '/', label: 'Home' },
    { to: '/how-it-works', label: 'How It Works' },
    { to: '/gallery', label: 'Gallery' },
    { to: '/upgrade-plan', label: 'Pricing' },
  ];

  const authItems = isLoggedIn
    ? [
        { label: 'Logout', onClick: handleLogout },
      ]
    : [
        { to: '/login', label: 'Login' },
        { to: '/register', label: 'Register' },
      ];

  return (
    <motion.nav 
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ type: "spring", stiffness: 100 }}
      className="bg-white shadow-md p-4 sticky top-0 z-50"
    >
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <img src="/logo192.png" alt="KidzBook Logo" className="w-8 h-8 mr-2" />
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-500 font-bold text-3xl font-display">
            KidzBook
          </span>
        </Link>
        
        {/* Desktop menu */}
        <div className="hidden md:flex items-center space-x-6">
          {menuItems.map((item) => (
            <Link key={item.to} to={item.to} className="text-gray-600 hover:text-pink-500 transition duration-300">
              {item.label}
            </Link>
          ))}
          <div className="border-l border-gray-300 h-6 mx-2"></div>
          {isLoggedIn ? (
            <>
              <Link to="/profile" className="text-gray-600 hover:text-pink-500 transition duration-300 flex items-center">
                <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
                <span>Profile</span>
              </Link>
              <button onClick={handleLogout} className="text-gray-600 hover:text-pink-500 transition duration-300">
                Logout
              </button>
            </>
          ) : (
            authItems.map((item) => (
              <Link key={item.to || item.label} to={item.to} onClick={item.onClick} className="text-gray-600 hover:text-pink-500 transition duration-300">
                {item.label}
              </Link>
            ))
          )}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <button onClick={handleCreateNow} className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-6 py-2 rounded-full hover:from-purple-600 hover:to-pink-600 transition duration-300 font-semibold shadow-lg">
              Create Now
            </button>
          </motion.div>
        </div>

        {/* Hamburger menu for mobile */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-purple-600 focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile menu */}
      {isMenuOpen && (
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className="md:hidden mt-4 bg-white rounded-lg shadow-lg p-4"
        >
          {menuItems.map((item) => (
            <Link 
              key={item.to} 
              to={item.to} 
              className="block py-2 text-gray-600 hover:text-pink-500 transition duration-300"
              onClick={toggleMenu}
            >
              {item.label}
            </Link>
          ))}
          {isLoggedIn ? (
            <>
              <Link 
                to="/profile" 
                className="block py-2 text-gray-600 hover:text-pink-500 transition duration-300"
                onClick={toggleMenu}
              >
                <span>Profile</span>
              </Link>
              <button
                onClick={() => {
                  handleLogout();
                  toggleMenu();
                }}
                className="block w-full text-left py-2 text-gray-600 hover:text-pink-500 transition duration-300"
              >
                Logout
              </button>
            </>
          ) : (
            authItems.map((item) => (
              <Link 
                key={item.to || item.label} 
                to={item.to} 
                onClick={() => {
                  if (item.onClick) item.onClick();
                  toggleMenu();
                }}
                className="block py-2 text-gray-600 hover:text-pink-500 transition duration-300"
              >
                {item.label}
              </Link>
            ))
          )}
          <button 
            onClick={() => {
              handleCreateNow();
              toggleMenu();
            }}
            className="block w-full py-2 text-white bg-gradient-to-r from-purple-500 to-pink-500 rounded-full px-4 mt-2 text-center"
          >
            Create Now
          </button>
        </motion.div>
      )}
    </motion.nav>
  );
};

export default Navigation;
