import React from 'react';
import { Link } from 'react-router-dom';
import SEO from './SEO';
import { motion, AnimatePresence } from 'framer-motion';
import { FaCalendar, FaUser, FaBookmark } from 'react-icons/fa';

const BlogPage = () => {
  const blogPosts = [
    {
      id: 1,
      title: "Create Personalized Children's Books Your Child Will Love",
      author: "Neri Bluman",
      date: "2024-10-08",
      excerpt: "Discover how Kidz-Book.com brings joy to families through personalized children's books...",
      image: "/images/personalized-books.jpg",
      category: "Personalization",
      url: "/Create-Personalized-Children's-Books"
    },
    {
      id: 2,
      title: "How a Personalized Storybook Sparked My Child's Imagination: Our Journey with AI-Powered Custom Storytelling",
      author: "Sarah J.",
      date: "2024-10-15",
      excerpt: "Explore how artificial intelligence is revolutionizing the world of children's books...",
      image: "/images/Alex-Ai-Generated.jpg",
      category: "Technology",
      url: "/Our-Journey-with-AI-Powered-Storytelling"
    },
    // Add more blog posts as needed
  ];

  return (
    <>
      <SEO 
        title="KidzBook Blog - Insights on AI and Personalized Children's Literature"
        description="Explore our blog for the latest insights on AI in personalized children's books, custom stories, and tips for young readers."
        keywords="personalized children's literature, AI books for kids, custom stories, reading tips"
      />
      <div className="min-h-screen bg-gradient-to-br from-purple-300 to-pink-200 py-12 px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="max-w-7xl mx-auto bg-white rounded-3xl shadow-xl overflow-hidden"
        >
          <div className="h-48 bg-gradient-to-r from-purple-700 via-purple-500 to-indigo-600 relative flex items-center justify-center">
            <h1 className="text-4xl font-bold text-white font-display text-center px-4 sm:px-0">Magical Blog Posts</h1>
          </div>
          <div className="p-6">
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6"
              >
                {blogPosts.map((post) => (
                  <motion.div
                    key={post.id}
                    className="bg-white rounded-xl shadow-md overflow-hidden cursor-pointer transform transition-all duration-300 hover:scale-105 hover:shadow-xl"
                    whileHover={{ y: -5 }}
                  >
                    <Link to={post.url}>
                      <div className="relative pb-[100%]">
                        <img
                          src={post.image}
                          alt={post.title}
                          className="absolute top-0 left-0 w-full h-full object-cover"
                        />
                        <div className="absolute top-2 right-2 bg-white rounded-full p-2 shadow-md">
                          <FaBookmark className="text-purple-600" />
                        </div>
                      </div>
                      <div className="p-4">
                        <h3 className="text-lg font-semibold text-gray-800 mb-1 truncate">{post.title}</h3>
                        <p className="text-sm text-gray-600 mb-2">By {post.author}</p>
                        <div className="flex flex-wrap justify-between items-center">
                          <span className="text-xs text-purple-600 font-semibold">{post.category}</span>
                          <span className="text-xs text-indigo-600">
                            <FaCalendar className="inline-block mr-1" />
                            {post.date}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </motion.div>
                ))}
              </motion.div>
            </AnimatePresence>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default BlogPage;
