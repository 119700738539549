import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  const bookVariants = {
    animate: {
      y: [0, -10, 0],
      rotate: [-5, 5, -5],
      transition: {
        y: {
          repeat: Infinity,
          duration: 2,
          ease: "easeInOut",
        },
        rotate: {
          repeat: Infinity,
          duration: 4,
          ease: "easeInOut",
        },
      },
    },
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-pink-200 to-purple-300 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-md w-full bg-white bg-opacity-90 rounded-3xl p-8 shadow-xl text-center"
      >
        <motion.div
          variants={bookVariants}
          animate="animate"
          className="mb-8 inline-block"
        >
          <span role="img" aria-label="Book on Vacation" style={{ fontSize: '100px' }}>
            📘🏖️
          </span>
        </motion.div>
        <h1 className="text-6xl font-bold text-purple-800 mb-4">Oops! 404</h1>
        <h2 className="text-2xl font-bold text-purple-700 mb-6">Page on Vacation!</h2>
        <p className="text-lg text-purple-600 mb-6">
          Looks like this page is taking a break. Let's go back to our library!
        </p>
        <motion.button
          onClick={handleGoHome}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white font-bold py-3 px-6 rounded-full hover:from-purple-600 hover:to-pink-600 transition duration-300 shadow-lg"
        >
          Return to Homepage
        </motion.button>
      </motion.div>
    </div>
  );
};

export default NotFound;
