import React, { createContext, useState, useContext, useEffect } from 'react';
import api from './api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [fullName, setFullName] = useState('');

  useEffect(() => {
    const checkAuthStatus = () => {
      const token = localStorage.getItem('token');
      const storedFullName = localStorage.getItem('fullName');
      if (token) {
        setIsLoggedIn(true);
        setFullName(storedFullName || '');
      } else {
        setIsLoggedIn(false);
        setFullName('');
      }
    };

    checkAuthStatus();
  }, []);

  const login = (token, refreshToken, userId, fullName) => {
    console.log('AuthContext - Login called with:', { token, refreshToken, userId, fullName });
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('userId', userId);
    localStorage.setItem('fullName', fullName);
    setIsLoggedIn(true);
    setFullName(fullName);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('fullName');
    setIsLoggedIn(false);
    setFullName('');
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, fullName, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);