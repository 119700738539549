import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaBook, FaInfo, FaUser, FaChevronRight } from 'react-icons/fa';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

// Load Stripe outside of the component
const stripePromise = loadStripe("pk_test_51Q4M02Gg7X6tqBhQicVGX2hPIqVgYOHeG1XaGSnFhYVJiPUZrRwxcgictQGjHPyfVXOYjlUbIgRVE319cAjk9N9T00HSct42p3");

const Profile = () => {
  const [user, setUser] = useState(null);
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditedUser] = useState(null);
  const [activeTab, setActiveTab] = useState('books');
  const [clientSecret, setClientSecret] = useState("");
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [dpmCheckerLink, setDpmCheckerLink] = useState("");
  const [userPlan, setUserPlan] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3004';
        
        const userResponse = await axios.get(`${apiBaseUrl}/api/user`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        console.log('User data from API:', userResponse.data);

        const userData = {
          ...userResponse.data,
          tempAvatar: userResponse.data.tempAvatar || 'avatar1'
        };

        setUser(userData);
        setEditedUser(userData);
        
        // Check if plan information exists in the user data
        if (userData.plan) {
          setUserPlan(userData.plan);
        } else {
          console.error('User plan information is missing from the API response');
          setError('Unable to retrieve plan information. Please contact support.');
        }

        const booksResponse = await axios.get(`${apiBaseUrl}/api/books`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setBooks(booksResponse.data);

        setLoading(false);
      } catch (err) {
        console.error('Error fetching user data:', err);
        if (err.response && err.response.status === 401) {
          // Token is invalid or expired
          localStorage.removeItem('token');
          navigate('/login', { state: { from: '/profile' } });
        } else {
          setError('An unexpected error occurred. Please try again.');
        }
        setLoading(false);
      }
    };

    fetchUserData();
  }, [navigate]);

  const getAvatarSrc = (tempAvatar) => {
    const avatarOptions = {
      avatar1: require('../images/fox.png'),
      avatar2: require('../images/owl.png'),
      avatar3: require('../images/panda.png'),
      avatar4: require('../images/rabbit.png'),
    };
    return avatarOptions[tempAvatar] || avatarOptions.avatar1;
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handleBookClick = (bookId) => {
    navigate(`/shared-book/${bookId}`);
  };

  const handleCreateNewBook = () => {
    if (userPlan) {
      const bookLimit = userPlan.books === 'Unlimited' ? Infinity : userPlan.books;
      if (books.length < bookLimit) {
        navigate('/child-theme-book');
      } else {
        // Redirect to the account tab when the book limit is reached
        setActiveTab('account');
        // Scroll to the account section
        const accountSection = document.getElementById('account-section');
        if (accountSection) {
          accountSection.scrollIntoView({ behavior: 'smooth' });
        }
      }
    } else {
      setError('Unable to determine your plan. Please try again or contact support.');
    }
  };

  const handleDeleteBook = async (bookId) => {
    try {
      const token = localStorage.getItem('token');
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3004';
      
      await axios.delete(`${apiBaseUrl}/api/books/${bookId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setBooks(books.filter(book => book._id !== bookId));
      setDeleteConfirmation(null);
    } catch (err) {
      console.error('Failed to delete book:', err);
      setError('Failed to delete book');
    }
  };

  const handleEditProfile = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedUser(user);
  };

  const handleSaveEdit = async () => {
    try {
      const token = localStorage.getItem('token');
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3004';
      
      const response = await axios.put(`${apiBaseUrl}/api/user`, 
        { ...editedUser, tempAvatar: editedUser.tempAvatar },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      console.log('Updated user data:', response.data);

      // Ensure tempAvatar is included in the response data
      const updatedUserData = {
        ...response.data,
        tempAvatar: response.data.tempAvatar || editedUser.tempAvatar
      };

      setUser(updatedUserData);
      setEditedUser(updatedUserData);
      setIsEditing(false);
    } catch (err) {
      console.error('Failed to update user profile:', err);
      setError('Failed to update user profile');
    }
  };

  const handleInputChange = (e) => {
    setEditedUser({ ...editedUser, [e.target.name]: e.target.value });
  };

  const handleAvatarChange = (avatar) => {
    setEditedUser({ ...editedUser, tempAvatar: avatar });
  };

  const handleUpgradePlan = () => {
    navigate('/upgrade-plan');
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!user) return <div>No user data found</div>;

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-300 to-pink-200 py-12 px-4 sm:px-6 lg:px-8">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-4xl mx-auto bg-white rounded-3xl shadow-xl overflow-hidden"
      >
        <div className="h-48 bg-gradient-to-r from-purple-700 via-purple-500 to-indigo-600 relative flex items-center justify-center">
          <h1 className="text-4xl font-bold text-white font-display">Your Profile</h1>
          <img 
            src={getAvatarSrc(user.tempAvatar)}
            alt="Profile" 
            className="absolute -bottom-16 left-8 w-32 h-32 rounded-full border-4 border-white shadow-lg"
          />
        </div>
        <div className="pt-16 px-8 pb-8">
          <div className="flex justify-between items-start mb-8">
            <div>
              {isEditing ? (
                <input
                  type="text"
                  name="fullName"
                  value={editedUser.fullName}
                  onChange={handleInputChange}
                  className="text-3xl font-bold text-gray-800 border-b-2 border-purple-500 focus:outline-none font-display"
                />
              ) : (
                <h2 className="text-3xl font-bold text-gray-800 font-display">{user.fullName}</h2>
              )}
              <p className="text-gray-600">{user.email}</p>
            </div>
            {isEditing ? (
              <div>
                <button 
                  onClick={handleSaveEdit}
                  className="px-4 py-2 bg-purple-700 text-white rounded-full hover:bg-purple-800 transition duration-300 mr-2"
                >
                  Save
                </button>
                <button 
                  onClick={handleCancelEdit}
                  className="px-4 py-2 border border-purple-700 text-purple-700 rounded-full hover:bg-purple-100 transition duration-300"
                >
                  Cancel
                </button>
              </div>
            ) : (
              <button 
                onClick={handleEditProfile}
                className="px-4 py-2 border border-purple-700 text-purple-700 rounded-full hover:bg-purple-700 hover:text-white transition duration-300"
              >
                Edit Profile
              </button>
            )}
          </div>
          {isEditing && (
            <div className="mb-8">
              <h3 className="text-xl font-semibold mb-4 text-purple-700">Change Avatar</h3>
              <div className="flex space-x-4">
                {['avatar1', 'avatar2', 'avatar3', 'avatar4'].map((avatar) => (
                  <img
                    key={avatar}
                    src={getAvatarSrc(avatar)}
                    alt={`Avatar ${avatar}`}
                    className={`w-16 h-16 rounded-full cursor-pointer ${editedUser.tempAvatar === avatar ? 'border-4 border-purple-500' : ''}`}
                    onClick={() => handleAvatarChange(avatar)}
                  />
                ))}
              </div>
            </div>
          )}
          <div className="bg-purple-100 rounded-full p-1 mb-8">
            <div className="flex justify-between">
              {['books', 'info', 'account'].map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`flex-1 py-2 px-4 rounded-full flex items-center justify-center ${
                    activeTab === tab ? 'bg-white text-purple-700 shadow-md' : 'text-purple-700'
                  }`}
                >
                  {tab === 'books' && <FaBook className="mr-2" />}
                  {tab === 'info' && <FaInfo className="mr-2" />}
                  {tab === 'account' && <FaUser className="mr-2" />}
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>
          </div>
          {activeTab === 'books' && (
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
              <motion.div 
                className="bg-gradient-to-br from-purple-100 to-pink-100 p-4 rounded-xl shadow-md cursor-pointer"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.2 }}
                onClick={handleCreateNewBook}
              >
                <div className="aspect-w-1 aspect-h-1 mb-2">
                  <img 
                    src={require('../images/create-new-book-placeholder.jpg')}
                    alt="Create a new book"
                    className="object-cover w-full h-full rounded-lg"
                  />
                </div>
                <h5 className="font-semibold text-purple-700 truncate">Create a New Book</h5>
                <p className="text-sm text-purple-600">Start your next adventure!</p>
              </motion.div>
              {books.map((book) => (
                <motion.div 
                  key={book._id}
                  className="bg-white p-4 rounded-xl shadow-md relative group"
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.2 }}
                  onClick={() => handleBookClick(book._id)}
                >
                  <div className="aspect-w-1 aspect-h-1 mb-2">
                    <img 
                      src={book.firstPageImage || book.data?.pages?.[0]?.imageUrl || "https://via.placeholder.com/150x150?text=No+Image"} 
                      alt={`First page of ${book.title}`}
                      className="object-cover w-full h-full rounded-lg"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "https://via.placeholder.com/150x150?text=No+Image";
                      }}
                    />
                  </div>
                  <h5 className="font-semibold text-gray-800 truncate">{book.title}</h5>
                  <p className="text-sm text-gray-600">Created: {new Date(book.createdAt).toLocaleDateString()}</p>
                  <motion.button
                    className="absolute top-2 right-2 bg-pink-100 text-white rounded-full w-8 h-8 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-all duration-300"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteConfirmation(book._id);
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                    </svg>
                  </motion.button>
                </motion.div>
              ))}
            </div>
          )}
          {activeTab === 'info' && (
            <div className="bg-white p-6 rounded-xl shadow-md">
              <h3 className="text-xl font-semibold mb-4 text-purple-700">Personal Information</h3>
              <p><strong>Name:</strong> {user.fullName}</p>
              <p><strong>Email:</strong> {user.email}</p>
              {/* Add more user info here */}
            </div>
          )}
          {activeTab === 'account' && (
            <div id="account-section" className="space-y-6">
              <div className="bg-white p-6 rounded-xl shadow-md">
                <h3 className="text-xl font-semibold mb-4 text-purple-700">Account Status</h3>
                {userPlan ? (
                  <>
                    <div className="mb-4">
                      <p className="text-sm text-gray-600 mb-1">Current Plan: {userPlan.name}</p>
                      <p className="text-sm text-gray-600 mb-1">Books Created</p>
                      <div className="w-full bg-gray-200 rounded-full h-2.5">
                        <div 
                          className="bg-purple-600 h-2.5 rounded-full" 
                          style={{ width: `${(books.length / (userPlan.books === 'Unlimited' ? books.length : userPlan.books)) * 100}%` }}
                        ></div>
                      </div>
                      <p className="text-sm text-gray-600 mt-1">
                        {books.length} / {userPlan.books === 'Unlimited' ? '∞' : userPlan.books}
                      </p>
                    </div>
                    <div className="bg-purple-100 p-4 rounded-lg">
                      <p className="text-purple-800">
                        {userPlan.books === 'Unlimited' 
                          ? 'You have unlimited book creation!' 
                          : books.length >= userPlan.books
                            ? 'You have reached your book limit. Please upgrade your plan to create more books.'
                            : `You have ${Math.max(0, userPlan.books - books.length)} book credits remaining.`
                        }
                      </p>
                    </div>
                  </>
                ) : (
                  <p className="text-red-500">Unable to retrieve plan information. Please contact support.</p>
                )}
              </div>
              <button 
                className="w-full bg-purple-700 text-white py-3 rounded-full hover:bg-purple-800 transition duration-300 flex items-center justify-center"
                onClick={handleUpgradePlan}
              >
                Upgrade Plan <FaChevronRight className="ml-2" />
              </button>
            </div>
          )}
        </div>
      </motion.div>

      {/* Delete Confirmation Dialog */}
      <AnimatePresence>
        {deleteConfirmation && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          >
            <motion.div
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.9 }}
              className="bg-white rounded-lg p-6 max-w-sm mx-4"
            >
              <h3 className="text-xl font-semibold mb-4 text-purple-700">Confirm Deletion</h3>
              <p className="mb-6">Are you sure you want to delete this book? This action cannot be undone.</p>
              <div className="flex justify-end space-x-4">
                <button
                  className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300 transition-colors"
                  onClick={() => setDeleteConfirmation(null)}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-pink-700 transition-colors"
                  onClick={() => handleDeleteBook(deleteConfirmation)}
                >
                  Delete
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Upgrade Plan Modal */}
      <AnimatePresence>
        {showUpgradeModal && clientSecret && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          >
            <motion.div
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.9 }}
              className="bg-white rounded-lg p-6 max-w-md mx-4 w-full"
            >
              <h3 className="text-2xl font-semibold mb-4 text-purple-700">Upgrade Your Plan</h3>
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm dpmCheckerLink={dpmCheckerLink} />
              </Elements>
              <button
                className="mt-4 w-full px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300 transition-colors"
                onClick={() => setShowUpgradeModal(false)}
              >
                Cancel
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Profile;