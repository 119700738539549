import React, { useEffect, useState } from "react";
import { useStripe, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useNavigate, useLocation } from 'react-router-dom';

// Load your Stripe publishable key
const stripePromise = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  ? loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
  : null;

const STATUS_CONTENT_MAP = {
  succeeded: {
    text: "Payment succeeded",
    iconColor: "#30B130",
    icon: "✓",
  },
  processing: {
    text: "Your payment is processing.",
    iconColor: "#6D6E78",
    icon: "ℹ",
  },
  requires_payment_method: {
    text: "Your payment was not successful, please try again.",
    iconColor: "#DF1B41",
    icon: "✗",
  },
  default: {
    text: "Something went wrong, please try again.",
    iconColor: "#DF1B41",
    icon: "✗",
  }
};

const PaymentCompleteContent = ({ navigate, location }) => {
  const stripe = useStripe();
  const [status, setStatus] = useState("default");
  const [intentId, setIntentId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [planUpdated, setPlanUpdated] = useState(false);
  const [planInfo, setPlanInfo] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(location.search).get('payment_intent_client_secret');

    if (!clientSecret) {
      setError("No payment intent client secret found.");
      setLoading(false);
      return;
    }

    const handlePaymentIntent = async (paymentIntent) => {
      setStatus(paymentIntent.status);
      setIntentId(paymentIntent.id);

      // Retrieve planId from paymentIntent.metadata or from URL parameters
      const planId = paymentIntent.metadata?.planId || new URLSearchParams(location.search).get('planId');

      if (!planId) {
        setError("No plan ID found. Please contact support.");
        setLoading(false);
        return;
      }

      if (paymentIntent.status === "succeeded") {
        try {
          const token = localStorage.getItem('token');
          const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3004';
          
          const response = await axios.post(
            `${apiBaseUrl}/api/update-user-plan`,
            { 
              paymentIntentId: paymentIntent.id,
              planId: planId
            },
            { headers: { Authorization: `Bearer ${token}` } }
          );
          
          setPlanUpdated(true);
          setPlanInfo(response.data.plan);
          setUserInfo(response.data.user);
        } catch (error) {
          setError('Failed to update user plan: ' + error.message);
        }
      }

      setLoading(false);
    };

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (!paymentIntent) {
        setError("No payment intent found.");
        setLoading(false);
        return;
      }

      handlePaymentIntent(paymentIntent);
    }).catch(err => {
      setError('Error retrieving payment intent: ' + err.message);
      setLoading(false);
    });
  }, [stripe, location.search]);

  const statusContent = STATUS_CONTENT_MAP[status] || STATUS_CONTENT_MAP.default;

  const handleReturnToProfile = () => {
    navigate('/profile');
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-purple-300 to-pink-200 py-12 px-4 sm:px-6 lg:px-8 flex items-center justify-center">
        <div className="bg-white p-8 rounded-xl shadow-xl max-w-md w-full text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-700 mx-auto mb-4"></div>
          <p className="text-lg">Processing payment...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-purple-300 to-pink-200 py-12 px-4 sm:px-6 lg:px-8 flex items-center justify-center">
        <div className="bg-white p-8 rounded-xl shadow-xl max-w-md w-full text-center">
          <h2 className="text-2xl font-bold text-red-600 mb-4">Error</h2>
          <p className="text-gray-700">{error}</p>
          <button 
            onClick={() => navigate('/profile')}
            className="mt-6 w-full bg-purple-700 text-white py-3 rounded-full hover:bg-purple-800 transition duration-300"
          >
            Return to Profile
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-300 to-pink-200 py-12 px-4 sm:px-6 lg:px-8 flex items-center justify-center">
      <div className="bg-white p-8 rounded-xl shadow-xl max-w-md w-full">
        <div className="flex items-center justify-center mb-6">
          <div className={`w-12 h-12 rounded-full flex items-center justify-center text-white text-2xl`} style={{backgroundColor: statusContent.iconColor}}>
            {statusContent.icon}
          </div>
        </div>
        <h2 className="text-2xl font-bold text-center mb-6">{statusContent.text}</h2>
        {intentId && (
          <div className="bg-gray-100 p-4 rounded-lg mb-6">
            <p className="text-sm"><span className="font-semibold">Payment ID:</span> {intentId}</p>
            <p className="text-sm"><span className="font-semibold">Status:</span> {status}</p>
          </div>
        )}
        {planUpdated && planInfo && userInfo && (
          <div className="bg-green-100 p-4 rounded-lg mb-6">
            <h3 className="text-lg font-semibold mb-2">Your plan has been successfully updated!</h3>
            <p><strong>Plan:</strong> {planInfo.name}</p>
            <p><strong>Books:</strong> {planInfo.books}</p>
            <p><strong>Support:</strong> {planInfo.support}</p>
            <p><strong>Type:</strong> {planInfo.type}</p>
            <p><strong>Remaining Books:</strong> {userInfo.remainingBooks}</p>
            {userInfo.planExpirationDate && (
              <p><strong>Expires:</strong> {new Date(userInfo.planExpirationDate).toLocaleDateString()}</p>
            )}
          </div>
        )}
        {intentId && (
          <a 
            href={`https://dashboard.stripe.com/payments/${intentId}`}
            className="block text-center text-purple-700 hover:underline mb-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            View details in Stripe Dashboard
          </a>
        )}
        <button 
          onClick={handleReturnToProfile}
          className="block w-full bg-purple-700 text-white py-3 rounded-full hover:bg-purple-800 transition duration-300 text-center mt-6"
        >
          Return to Profile
        </button>
      </div>
    </div>
  );
};

const PaymentComplete = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    const secretFromURL = new URLSearchParams(location.search).get('payment_intent_client_secret');
    setClientSecret(secretFromURL);
  }, [location.search]);

  if (!clientSecret) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-purple-300 to-pink-200 py-12 px-4 sm:px-6 lg:px-8 flex items-center justify-center">
        <div className="bg-white p-8 rounded-xl shadow-xl max-w-md w-full text-center">
          <h2 className="text-2xl font-bold text-red-600 mb-4">Error</h2>
          <p className="text-gray-700">No payment information found. Please try making the payment again.</p>
          <button 
            onClick={() => navigate('/upgrade-plan')}
            className="mt-6 w-full bg-purple-700 text-white py-3 rounded-full hover:bg-purple-800 transition duration-300"
          >
            Return to Upgrade Plan
          </button>
        </div>
      </div>
    );
  }

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <PaymentCompleteContent navigate={navigate} location={location} />
    </Elements>
  );
};

export default PaymentComplete;