import React from 'react';
import { Link } from 'react-router-dom';
import SEO from './SEO';
import { motion } from 'framer-motion';
import { FaCalendar, FaUser, FaArrowLeft, FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';

const BlogPost1 = () => {
  const post = {
    title: "Create Personalized Children's Books Your Child Will Love",
    author: "Neri Bluman",
    date: "2024-10-08",
    image: "/images/personalized-books.jpg",
    authorImage: "/images/author-neri.jpg",
    content: `
      <div class="mb-12 flex flex-col md:flex-row items-start">
        <img src="/images/personalized-books.jpg" alt="Personalized children's books" class="w-full md:w-1/3 h-auto object-cover rounded-lg shadow-md mb-8 md:mb-0 md:mr-8" />
        <div class="md:w-2/3">
          <p class="text-2xl italic leading-relaxed mb-4">Have you ever imagined your child starring in their very own storybook?</p>
          <p class="text-lg leading-relaxed">At Kidz-Book.com, we make that dream a reality by creating personalized children's books that put your child at the center of the adventure. Let me share how this heartfelt project began and how it can bring joy to your family.</p>
        </div>
      </div>

      <section class="mb-12 p-8 bg-purple-50 rounded-lg">
        <h2 class="text-3xl font-bold mb-4">Our Story</h2>
        <p class="text-lg leading-relaxed">My wife and I were living far away from a young friend of ours—a child we are really close to and care about deeply. We wanted to find a special way to stay connected with him despite the distance. So, we decided to create a personalized storybook featuring ourselves and him, complete with our faces and names. To our delight, it became his favorite book! Seeing his excitement inspired us to make this experience available to other families.</p>
      </section>

      <section class="my-12 flex flex-col md:flex-row-reverse items-center md:items-start">
        <img src="/images/kidz-book-sample.jpg" alt="Sample personalized book" class="rounded-lg shadow-md w-full md:w-1/2 mb-8 md:mb-0 md:ml-8" />
        <div>
          <h2 class="text-3xl font-bold mb-4">Why Personalized Children's Books Matter</h2>
          <p class="text-lg leading-relaxed">Personalized children's books offer a unique way to engage kids in reading. When children see themselves in a story, it captures their imagination and makes reading more enjoyable. It's not just about the story itself but about creating lasting memories and strengthening bonds.</p>
        </div>
      </section>

      <section class="my-12">
        <h2 class="text-3xl font-bold mb-4">How Kidz-Book.com Works</h2>
        <p class="text-lg mb-4">Creating your own personalized book is simple:</p>
        <ol class="list-decimal list-inside text-lg space-y-2 mb-8">
          <li>Visit Our Website: Go to Kidz-Book.com and sign up for a free account.</li>
          <li>Customize Your Story: Choose a theme that fits your child's interests—whether it's potty training, exploring feelings, or baking with mom—and add personal details like names and photos.</li>
          <li>Enjoy Your Book: Instantly access your digital book. We're offering the first five books for free!</li>
          <li>Printed Books Coming Soon: We're working on providing printed versions so your child can hold their special story in their hands.</li>
        </ol>
      </section>

      <section class="my-12 p-8 bg-purple-50 rounded-lg">
        <h2 class="text-3xl font-bold mb-4">What Makes Kidz-Book.com Special</h2>
        <ul class="list-disc list-inside text-lg space-y-2">
          <li>Easy to Use: No tech skills required. Our website guides you through each step.</li>
          <li>Meaningful Content: Stories that reflect everyday activities and important life lessons.</li>
          <li>Strengthen Connections: A personalized book is a wonderful way to connect with your child or a child you care about.</li>
        </ul>
      </section>

      <section class="my-12">
        <h2 class="text-3xl font-bold mb-4">Join Our Community</h2>
        <p class="text-lg leading-relaxed mb-8">We're reaching out to parents and caregivers who want to make reading a more personal and engaging experience for their children. We're not seeking funding; we're simply passionate about sharing this with others. Your feedback and suggestions are invaluable as we continue to improve.</p>
        <img src="/images/kidz-book-community.jpg" alt="Kidz-Book community" class="w-full rounded-lg shadow-md" />
      </section>

      <section class="my-12">
        <h2 class="text-3xl font-bold mb-4">Get Started Today</h2>
        <p class="text-lg leading-relaxed mb-8">Ready to create a cherished keepsake for your child? Visit Kidz-Book.com to start crafting your personalized children's book. Let's make storytime unforgettable!</p>
        <p class="text-lg italic">At Kidz-Book.com, we're dedicated to bringing families closer through the magic of personalized storytelling. We invite you to be part of our journey and create memories that will last a lifetime.</p>
      </section>
    `
  };

  return (
    <>
      <SEO 
        title={`${post.title} | KidzBook Blog`}
        description="Discover how Kidz-Book.com brings joy to families through personalized children's books."
        keywords="personalized children's books, AI books for kids, custom stories"
      />
      <div className="min-h-screen bg-gradient-to-br from-purple-300 to-pink-200 py-12 px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="max-w-4xl mx-auto bg-white rounded-3xl shadow-xl overflow-hidden"
        >
          <div className="h-64 bg-gradient-to-r from-purple-700 via-purple-500 to-indigo-600 relative flex items-center justify-center">
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-white font-display text-center px-4 sm:px-0 leading-tight">
              {post.title}
            </h1>
          </div>
          <div className="p-8">
            <div className="flex flex-col sm:flex-row justify-between items-center mb-8 space-y-4 sm:space-y-0">
              <Link to="/blog" className="inline-flex items-center text-purple-600 hover:underline text-lg">
                <FaArrowLeft className="mr-2" /> Back to Blog
              </Link>
              <Link 
                to="/child-theme-book" 
                className="bg-purple-600 text-white px-4 sm:px-6 py-2 rounded-full hover:bg-purple-700 transition duration-300 text-center w-full sm:w-auto"
              >
                Create Book
              </Link>
            </div>
            <div className="flex justify-between items-center mb-8 text-gray-600">
              <p className="flex items-center">
                <FaCalendar className="mr-2" />
                {post.date}
              </p>
              <p className="flex items-center">
                <FaUser className="mr-2" />
                {post.author}
              </p>
            </div>
            <div className="prose max-w-none text-lg" dangerouslySetInnerHTML={{ __html: post.content }} />
            <div className="mt-12 pt-8 border-t border-gray-200">
              <h3 className="text-2xl font-bold mb-4">Share this post</h3>
              <div className="flex space-x-4">
                <FaFacebook className="text-3xl text-blue-600 cursor-pointer" />
                <FaTwitter className="text-3xl text-blue-400 cursor-pointer" />
                <FaLinkedin className="text-3xl text-blue-700 cursor-pointer" />
              </div>
            </div>
            <div className="mt-12 pt-8 border-t border-gray-200 flex items-center">
              <img src={post.authorImage} alt={post.author} className="w-20 h-20 rounded-full mr-6" />
              <div>
                <h3 className="text-xl font-bold mb-2">{post.author}</h3>
                <p className="text-gray-600">Neri is a passionate storyteller and the co-founder of Kidz-Book.com. With a background in education and technology, she's dedicated to making reading a magical experience for children around the world.</p>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default BlogPost1;